.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(107, 102, 102, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  /*width: 80%;*/
  max-height: 85%;
  overflow: hidden;
  position: relative;
}

.scrollable-content {
  max-height: 500px; /* Adjust based on your design */
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.logout-button {
  position: absolute;
  right: 20px;
  top: 80px;
}

.user-info {
  position: absolute;
  right: 20px;
  top: 50px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px; /* space between back button and heading */
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.heading {
  margin: 0;
}

.text_total {
  color: rgb(69, 36, 199);
  font-size: 18px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.text_payment {
  color: rgb(26, 83, 31);
  font-size: 18px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.text_balance {
  color: red;
  font-size: 18px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

/* Modal content */
.modal-content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

/* Modal content 
.modal-content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}
*/
